html, body { 

  margin: 0;
  padding: 0;
}

body {


}

.error {
    color: red;
    padding-bottom: 10px;
    margin: 0;
  }

  .left {
    text-align: left;
  }
  

a:hover {

  color: inherit;
  text-decoration: none;
}
/* width */
div::-webkit-scrollbar {
	width: 8px;
	background-color: transparent;
}

/* Track */
div::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: transparent;
}

/* Handle */
div::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}
