html, body { 

  margin: 0;
  padding: 0;
}

body {


}

.error {
    color: red;
    padding-bottom: 10px;
    margin: 0;
  }

  .left {
    text-align: left;
  }
  

a:hover {

  color: inherit;
  text-decoration: none;
}